import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submitForm(event) {
    const form = event.target.closest('form');
    if (form) {
      form.requestSubmit();
    }
  }
}
