import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ["avatarPreview", "fileUpload", "editAvatarLink"];

  connect() {
    this.editAvatarLinkTarget.addEventListener("click", this.editAvatar.bind(this));
    this.fileUploadTarget.addEventListener("change", this.handleFileUpload.bind(this));
  }

  editAvatar(event) {
    event.preventDefault();
    this.fileUploadTarget.click();
  }

  handleFileUpload(event) {
    const files = event.target.files;

    if (window.NativeFileReader && files && files.length) {
      const fr = new NativeFileReader();
      fr.onloadend = () => this.showImage(fr);
      fr.readAsDataURL(files[0]);
    }
  }

  showImage(fileReader) {
    if (this.avatarPreviewTarget.tagName === "IMG") {
      this.avatarPreviewTarget.src = fileReader.result;
    } else {
      // If it's not an image (e.g., it's a div with an icon), replace it with an img
      const img = document.createElement('img');
      img.src = fileReader.result;
      img.alt = "Avatar Preview";
      img.id = "avatar_preview";
      img.dataset.avatarTarget = "avatarPreview";

      // Replace the div with the new img
      this.avatarPreviewTarget.replaceWith(img);
    }
  }
}
